<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!-- 교육과정 -->
              <c-multi-select
                :comboItems="comboItems"
                type="search"
                itemText="codeName"
                itemValue="code"
                name="education"
                label="교육과정"
                v-model="searchParam.education"
              />
          </div>
        </template>
      </c-search-box>
      <c-card :noHeader="true" class="cardClassDetailForm dashborad">
        <template slot="card-detail">
          <div class="col-12">
            <apexchart 
              ref="chart" 
              height="450" 
              type="bar"
              :width="chart.chartWidth"
              :options="chart.chartOptions" 
              :series="chart.series"></apexchart>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'week-dashboard',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      searchParam: {
        plantCd: '',
        education: [ '1', '2', '3', '4', '5'],
      },
      chart: {
        chartOptions: {
          title: {
            text: '교육과정별 계획/완료'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded',
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
              return val + "건";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['비상사태 대응 훈련', '수급업체 안전교육',
             '신입사원 공정안전보고서', 'PSM 관련교육', '폐기물 배출자'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1,
            colors: ['#1E90FF', '#C10015']
          },
          colors: ['#1E90FF', '#C10015'],
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          }
        },
        series: [{
          name: '계획 건 수',
          // data: [0, 0, 0, 0, 0]
          data: [10, 9, 5, 8, 6]
        }, {
          name: '완료 건 수',
          // data: [0, 0, 0, 0, 0]
          data: [8, 7, 5, 6, 2]
        }],
        chartWidth: '80%',
      },
      comboItems: [
        { code: '1', codeName: '비상사태 대응 훈련' },
        { code: '2', codeName: '수급업체 안전교육' },
        { code: '3', codeName: '신입사원 공정안전보고서' },
        { code: '4', codeName: 'PSM 관련교육' },
        { code: '5', codeName: '폐기물 배출자' },
      ],
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    // window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.setChart();
      this.getList();
    },
    setChart() {
      setTimeout(() => {
        this.chart.chartWidth = '100%';
      }, 200);
      this.$refs['chart'].refresh();
    },
  }
};
</script>